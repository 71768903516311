import styled from "@emotion/styled/macro"
import React from 'react'
import { COLORS } from "../../../constants/colors"
import { facebook, instagram, linkedin, pinterest, spotify, tiktok, vimeo, youtube } from '../../components/socialIcons'
import { scenesState, sequenceState } from "../../state/sequence"
import SceneUploader from "../../ui-components/SceneUploader"
import { onProjectTitleKeyPress } from "../../utils/input.utils"
import RatioExplanation, { Explanation } from './RatioExplanation'

const Wrapper = styled.div` 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ChaptersQueue = styled.div`
    width: 46%;
    height: 50%;
    min-height: 350px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
   
    .animatedDiv {
        width: 82%;
        max-width: 460px;
        
    }

    .scene-uploader-wrapper {
        width: 100%;
        max-width: 100%;
        margin: 8px;
        height: 100px;
    }
    .peech-scene-uploader {
        height: 100%;
        width: 100%;
        max-width: 100%;
        padding: 0 10px 0 0;
    }
    .peech-scene-uploader-info {
        width: 100%;
        max-width: 50%;
    }

    .scene-name {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

`
const FictiveChapters = styled.div<{ isActive: boolean }>`
    position: absolute;
    right: 10px;
    top: 0px;
    width: 82%;
    max-width: 460px;
    height: 50%;
    min-height: 350px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: ${({ isActive }): string => (isActive ? "1" : "0")};
    transition: opacity;   
    transition-duration: 1500ms;
    transition-timing-function: cubic-bezier(1,.12,1,.12);
    @keyframes taadaa { 
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}
`
const FicWrap = styled.div`
    width: 40%;
    min-width: 177px;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const FicPic = styled.div`
    background: ${COLORS.peech_gray_100};
    box-shadow: 0 0 2px ${COLORS.peech_gray_100};
    animation: taadaa 2s infinite;
    height: 105px;
    width: 177px;
    border-radius: 6px;
    /* margin: 5px; */
   
`

const FicRow = styled(FicPic) <{ width: string }>`
    width: ${({ width }): string => width};
    min-width: 80px;
    height: 20px;
    margin: 5px;
    border-radius: 0;
    margin-left: 33px;
`

const FictiveChapterPlug = styled.div`
    width: 94%;
    height: 100px;
    margin: 8px;
    border-radius: 6px;
    background-color: ${COLORS.peech_gray_50};
    z-index: 3;
    display: flex;
    flex-direction: row;
`

const FictiveExamplePlug = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: ${COLORS.peech_neutral_white};
    background: ${COLORS.peech_gray_100};
    box-shadow: 0 0 2px ${COLORS.peech_gray_700};
    z-index: 3;
    div {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: ${COLORS.peech_neutral_white};
        box-shadow: 0 0 2px ${COLORS.peech_gray_700};
        animation: taadaa 2s infinite;
    }
`
const BluredBlock = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: ${COLORS.peech_gray_100};
    opacity: 0.5;
    z-index: 4;
`

const NameAndRatio = styled.div`
    width: 54%;
    height: 50%;
    min-height: 350px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 62px;
`

const NameBlock = styled.div`
    width: 82%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    input {
        margin-top: 5px;
        width: 460px;
        max-width: 100%;
        height: 37px;

        border: 0.5px solid;
        border-color: ${COLORS.peech_gray_400};
        border-radius: 3px;
        background-color: ${COLORS.peech_neutral_white};
        padding: 8.5px 10px;
        color: ${COLORS.peech_gray_900};
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
    }
    input:focus {
        outline: none !important;
        border: 1px solid ${COLORS.peech_gray_700};
        box-shadow: 0 0 1px ${COLORS.peech_gray_700};
  }
    
`
const RatioBlock = styled.div`
    width: 82%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
`

const Text = styled.div`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: ${COLORS.peech_neutral_black};
`

const Examples = styled.div`
    width: 100%;
    max-width: 461px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`

const ExampleItem = styled.div`
    background: ${COLORS.peech_neutral_white};
    border: 0.5px solid ${COLORS.peech_gray_100};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 135px;
    height: 160px;
    position: relative;
    cursor: pointer;

`
const ExItemHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    background: ${COLORS.peech_gray_100};
    border-radius: 5px 5px 0px 0px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${COLORS.peech_secondary_500};
    span {
        font-weight: 600;
    }
`
const BlackBlock = styled.div<{ width: string, height: string }>`
    width: ${({ width }): string => width};
    height: ${({ height }): string => height};
    background: ${COLORS.peech_gray_900};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity;   
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(.55,.1,.41,.83);
`

const ExItemBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 128px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: ${COLORS.peech_neutral_white};
    position: relative;

    &:hover {
        ${Explanation} {
            opacity: 1;
        }
        ${BlackBlock} {
            opacity: 0;
        }
    }
`



export const CheckBlock = styled.div`
    border: solid 1px ${COLORS.peech_gray_900};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: -32px;
    left: calc(50% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Checked = styled.div<{disabled?: boolean}>`
    width: 10px;
    height: 10px;
    background-color: ${({ disabled }): string => (disabled? COLORS.peech_gray_400 : COLORS.peech_gray_900)};
    border-radius: 50%;
`

const ratioExamples = [
    {
        headerTitle: 'Horizontal',
        ratioSize: '16:9',
        text: 'Great for YouTube, Vimeo, Instagram, Facebook, LinkedIn, Spotify',
        icons: [youtube, vimeo, instagram, facebook, linkedin, spotify],
        width: '107px',
        height: '54px',
    },
    {
        headerTitle: 'Square',
        ratioSize: '1:1',
        text: 'Great for Facebook feed, Instagram feed, LinkedIn, Pinterest',
        icons: [facebook, instagram, linkedin, pinterest],
        width: '73px',
        height: '68px'
    },
    {
        headerTitle: 'Vertical',
        ratioSize: '9:16',
        text: 'Great for Instagram story, Reels, TikTok, YouTube Shorts',
        icons: [instagram, tiktok, youtube],
        width: '65px',
        height: '110px'
    },

]




interface MaterialsChaptersAndRatioProps {
    onChapterRemove: Function;
    sequenceTitle: String;
    onTitleChange: Function;
    setCreateFlowStep: Function;
    sequenceRatio: string;
    setSequenceRatio: Function;
}



export default function MaterialsChaptersAndRatio({ onChapterRemove, sequenceTitle, onTitleChange, setCreateFlowStep, sequenceRatio, setSequenceRatio }: MaterialsChaptersAndRatioProps) {
    const sequence = sequenceState.use();
    const chapters = scenesState.use(scenes => scenes.filter.chapters);
    const titleInput = React.useRef();
    const aspecrRatioChangedByUserRef = React.useRef(false);

   
    React.useEffect(() => {
        titleInput.current && titleInput.current.focus();
        if (chapters.length && !aspecrRatioChangedByUserRef.current) {
            setSequenceRatio(chapters[0].values.aspectRatio);
        }
    }, [titleInput.current, chapters]);

    const onRatioChange = (ratio: string) => {
        if (chapters && chapters.length) {
            sequence.aspectRatio = ratio;
            sequenceState.set(sequence);
            setSequenceRatio(ratio);
            aspecrRatioChangedByUserRef.current = true;
        }
    }

    const handleChange = (event) => {
        onTitleChange(event.target.value);
    };

    function removeChapter(sid) {
        onChapterRemove(sid);
        if (chapters.length === 1) {
            setCreateFlowStep(0);
        }
    }


    return (
        <Wrapper>
            <ChaptersQueue>
                <FictiveChapters
                    isActive={!chapters || !chapters.length}
                >
                    {['0', '1', '2'].map(x => {
                        return <FictiveChapterPlug key={x}>
                            <FicWrap>
                                <FicPic />
                            </FicWrap>
                            <FicWrap>
                                <FicRow width={'90%'} />
                                <FicRow width={'60%'} />
                            </FicWrap>
                        </FictiveChapterPlug>
                    })}
                </FictiveChapters>
                {chapters.map((chapter, index) => <SceneUploader
                    key={chapter.sid}
                    sid={chapter.sid}
                    assetSid={chapter.assetSid}
                    thumb={chapter.thumb}
                    name={chapter.name}
                    fileSize={chapter.size}
                    duration={chapter.duration}
                    onRemove={removeChapter}
                    hideProgress={true}
                    index={index}
                />)}
            </ChaptersQueue>
            <NameAndRatio>
                <NameBlock>
                    <Text>
                        Give your video a title so you can find it later:
                    </Text>
                    <input
                        data-cy="wizard-title-input"
                        ref={titleInput}
                        type={'text'}
                        placeholder='Video title'
                        id={'project-title'}
                        value={sequenceTitle}
                        onChange={handleChange}
                        onKeyDown={onProjectTitleKeyPress}
                        maxLength={60}
                    />
                </NameBlock>
                <RatioBlock>
                    <Text>
                        What size should the video be?
                    </Text>
                    <Examples>
                        {ratioExamples.map((item, index) => {
                            return <ExampleItem key={index} onClick={() => onRatioChange(item.ratioSize)} >
                                {!chapters || !chapters.length &&
                                    <FictiveExamplePlug >
                                        <div />
                                    </FictiveExamplePlug>}
                                <ExItemHeader>
                                    {item.headerTitle}
                                </ExItemHeader>
                                <ExItemBody>
                                    <BlackBlock
                                        width={item.width} height={item.height}>
                                        {item.ratioSize}
                                    </BlackBlock>
                                    <RatioExplanation
                                        text={item.text}
                                        iconsArray={item.icons} />
                                </ExItemBody>
                                {(chapters && chapters.length) &&
                                    <CheckBlock>
                                        {sequenceRatio === item.ratioSize && <Checked />}
                                    </CheckBlock>
                                }
                            </ExampleItem>
                        })}
                    </Examples>
                </RatioBlock>
            </NameAndRatio>
        </Wrapper>
    )

}